
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Icon extends Vue {
  @Prop() name: string; //.svgの前の部分まで
  @Prop() color: string; //#を入れたカラーコードか、rgbなど　currentColorをしようするならセット不要
  @Prop() size: string; //数字のみ pxは後付けされる 高さと横幅は同じ前提
  @Prop() spsize?: string; //なくても良い　数字のみ pxは後付けされる 高さと横幅は同じ前提
  @Prop() verticalalign?: string;
  
  get href() {
    return "/img/common/"+this.name+".svg#"+this.name;
  }

  get style() {
    return {
      "--size": this.size+"px",
      "--spsize": this.spsize ? this.spsize+"px" : this.size+"px",
      "--color": this.color ?? 'initial',
      "--verticalalign": this.verticalalign ? this.verticalalign : 'middle',
    };
  }
}
