
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import dialog from "@/vuex/dialog";
  import mixinForm from "@/mixins/mixinForm";
  import FIcon from "@/components/f/Icon.vue";

  @Component({
    components: {
      FIcon,
    },
  })
  export default class Button extends Mixins(mixinForm) {
    @Prop() icon: string;
    @Prop({ default: "#FFFFFF" }) icon_color: string;
    @Prop({ default: 16 }) icon_size: number;
    @Prop({ default: "-4px" }) icon_verticalalign: string;
    @Prop() type: string;
    @Prop() file: string;
    @Prop({ default: "normal" }) button_style: string;
    @Prop({ default: true }) isvalid: boolean | boolean[];
    @Prop() disable_reason: any;
    @Prop({ default: false }) border: boolean;
    @Prop() input_type: string;
    @Prop() ref_value?: string;
    @Prop({ default: false }) blink: boolean;
    @Prop({ default: "30px" }) width: string;
    @Prop({ default: "30px" }) height: string;

    get styles() {
      return {
        "--width": this.width,
        "--height": this.height,
      };
    }

    get use_isvalid() {
      if (Array.isArray(this.isvalid)) {
        let isvalid = true;
        this.isvalid.forEach((now) => {
          if (!now) {
            isvalid = false;
          }
        });
        return isvalid;
      } else {
        return this.isvalid;
      }
    }

    clicked() {
      this.$emit("clicked", true);
    }

    disable_error() {
      let str = [];

      if (Array.isArray(this.isvalid)) {
        this.isvalid.forEach((now, i) => {
          if (!now) {
            str = str.concat(this.disable_reason[i]);
          }
        });
      } else {
        str = this.disable_reason;
      }
      dialog.openErrorDialog({ error: str });
    }

    selected(e) {
      this.$emit("selected", e);
    }

    get refs(): any {
      return this.$refs;
    }

    reset_file() {
      this.refs[this.ref_value].value = null;
    }
  }
