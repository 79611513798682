import { createDecorator } from 'vue-class-component';
import { InjectKey } from 'vue/types/options';
import { computed } from 'vue';

interface ProvideObj {
  managed?: { [k: string]: any }; // key: name of a property to inject, value: injection key
}

type ProvideFunc = ((this: any) => Object) & ProvideObj;

export type InjectOptions = { from?: InjectKey; default?: any }


export function needToProduceProvide(original: any) {
  return typeof original !== 'function' || !original.managed;
}

export function makeProvide(original: any) {
  const provide: ProvideFunc = function () {
    let rv = typeof original === 'function' ? original.call(this) : original;
    rv = Object.create(rv || null);
    for (const i in provide.managed) {
      const injectionKey = provide.managed[i];
      rv[injectionKey] = computed(() => this[i]);
    }
    return rv;
  }
  provide.managed = {};
  return provide;
}

export function Provide(key?: string | symbol) {
  return createDecorator((componentOptions, k) => {
    let provide: any = componentOptions.provide;
    if (needToProduceProvide(provide)) {
      provide = componentOptions.provide = makeProvide(provide);
    }
    provide.managed[k] = key || k;
  });
}

export function Inject(options?: InjectOptions | InjectKey) {
  return createDecorator((componentOptions, key) => {
    const inject = componentOptions.inject;
    if (inject === null || typeof inject !== 'object' || Array.isArray(inject)) {
      componentOptions.inject = {};
      if (Array.isArray(inject)) {
        for (const item of inject) {
          componentOptions.inject[item] = item;
        }
      }
    }
    (componentOptions.inject as { [key: string]: string | Symbol | Object })[key] = options || key;
  });
}