import { Component, Vue, Prop, Provide } from "vue-property-decorator";
import { Inject } from "@/library/provide";

@Component
export default class mixinForm extends Vue {
  @Prop() is_edit_custom: boolean;
  @Prop() is_add_custom: boolean;
  @Prop({ default: true }) is_input_edit: boolean;
  @Prop({ default: true }) is_input_add: boolean;

  @Provide("is_edit")
  get use_is_edit() {
    if (this.is_edit_custom !== undefined) {
      return this.is_edit_custom;
    } else {
      return this.is_edit;
    }
  }
  @Provide("is_add")
  get use_is_add() {
    if (this.is_add_custom !== undefined) {
      return this.is_add_custom;
    } else {
      return this.is_add;
    }
  }
  get is_input() {
    return (this.is_input_edit && this.use_is_edit) || (this.is_input_add && this.use_is_add);
  }

  @Inject({ default: false })
  is_edit: boolean | number;
  @Inject({ default: false })
  is_add: boolean;
}
